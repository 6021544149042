'use strict';

import './structure/adjustVh';
import './structure/adjustViewport';
import NavEvent from './components/navEvent';
import InViewEvent from './components/inViewEvent';
import SwiperEvent from './components/swiperEvent';
import PerspectiveEvent from './components/perspectiveEvent';
import MemberEvent from './components/memberEvent';
import BarbaEvent from './components/barbaEvent';
import lottie from 'lottie-web';
import imagesLoaded from 'imagesloaded';
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
document.addEventListener('DOMContentLoaded', () => {
  // console.log('Hello World');
  const navEvent = new NavEvent();
  const inViewEvent = new InViewEvent();
  const swiperEvent = new SwiperEvent();
  const perspectiveEvent = new PerspectiveEvent();
  const memberEvent = new MemberEvent();
  const barbaEvent = new BarbaEvent();

  // TOP
  if (!document.querySelector('.topPage')) {
    return false;
  }
  const OPLogo = document.querySelector('.topKvLottie');
  const KV = document.querySelector('.p_kvInner');
  const TopKVScroll = document.querySelector('.topKVScroll');
  const TopKVCopyRight = document.querySelector('.topKVCopyRight');
  const topKv = document.querySelector('.topKv');

  const urlHash = location.hash;
  setTimeout(() => {
    const y = window.scrollY;
    if (y > 0 && !urlHash) {
      setTimeout(() => window.scrollTo(0, 0), 100);
    } else if (urlHash) {
      TopKVScroll.classList.remove('None');
      TopKVCopyRight.classList.remove('None');
      topKv.classList.remove('Black');
      KV.classList.add('None');
      setTimeout(() => KV.classList.remove('None'), 2000);
    }
  }, 0);

  const animationTivel = lottie.loadAnimation({
    container: OPLogo,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: '/assets/images/logo_motion.json',
  });

  disableBodyScroll(topKv);
  imagesLoaded(topKv, function (instance) {
    animationTivel.play();
    setTimeout(() => {
      enableBodyScroll(topKv);
    }, 1000);
    setTimeout(() => {
      TopKVScroll.classList.remove('None');
      TopKVCopyRight.classList.remove('None');
      topKv.classList.remove('Black');
    }, 1000);
  });

  const WH = window.innerWidth * 0.1;
  let scroll_y = window.scrollY;
  window.addEventListener('scroll', (event) => {
    scroll_y = window.scrollY;
    kvScroll();
  });

  kvScroll();
  function kvScroll() {
    scroll_y = window.scrollY;
    if (scroll_y > WH) {
      KV.classList.add('IntoPage');
    } else {
      KV.classList.remove('IntoPage');
    }
    if (scroll_y > WH) {
      TopKVScroll.classList.add('Hide');
      TopKVCopyRight.classList.add('Hide');
    } else {
      TopKVScroll.classList.remove('Hide');
      TopKVCopyRight.classList.remove('Hide');
    }
  }

  const company = document.querySelector('.p_companyBodyInner');
  const toggle = document.querySelector('.p_headerNavToggle');
  const mainNav = document.querySelector('.p_headerNavMain');
  const observerNav = new IntersectionObserver(
    (entries) => {
      // entriesは監視対象すべてが入っているリスト
      for (const e of entries) {
        // isIntersecting プロパティは交差しているかどうかの真偽値
        // viewport に交差し、入ったときに isIntersecting === true、出たときに false になる
        // console.log(e);
        if (e.isIntersecting) {
          // console.log(e);
          // toggle.classList.add('Reverse');
        } else {
          // console.log(e);
          // toggle.classList.remove('Reverse');
        }
        if (e.boundingClientRect.top > 0) {
          // console.log('画面より下に表示中');
          toggle.classList.remove('Reverse');
          mainNav.classList.remove('Black');
        } else {
          // console.log('画面より上に表示中');
          toggle.classList.add('Reverse');
          mainNav.classList.add('Black');
        }
      }
    },
    {
      root: null, //ターゲットが見えるかどうかを確認するためのビューポート, nullはブラウザビューポート
      rootMargin: '0% 0% 0% 0%', //rootの周りのマージンの値
      threshold: [0, 1],
    }
  );
  // observe メソッドに監視対象要素を引数として渡すことで監視されるようになります
  observerNav.observe(company);
});
