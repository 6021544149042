import inView from 'in-view';

export default class InViewEvent {
  constructor() {
    this.init();
  }
  init() {
    inView.threshold(0.2);
    inView('.js-inView')
      .on('enter', function (el) {
        el.classList.add('js-inViewAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewAct');
      });

    inView('.js-inViewSplit')
      .on('enter', function (el) {
        el.classList.add('js-inViewSplitAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewAct');
      });

    inView('.js-inViewMatrixA ')
      .on('enter', function (el) {
        el.classList.add('js-inViewMatrixAAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewMatrixAAct');
      });

    inView('.js-inViewHorizonVertLine')
      .on('enter', function (el) {
        el.classList.add('js-inViewHorizonVertLineAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewAct');
      });

    inView('.js-inViewVerticalLine')
      .on('enter', function (el) {
        el.classList.add('js-inViewVerticalLineAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewAct');
      });
    inView('.js-inViewHorizonLine')
      .on('enter', function (el) {
        el.classList.add('js-inViewHorizonLineAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewAct');
      });
    inView('.js-inViewHorizonShortLine')
      .on('enter', function (el) {
        el.classList.add('js-inViewHorizonShortLineAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewAct');
      });
  }
}
