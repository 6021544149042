export default class MemberEvent {
  constructor() {
    this.init();
  }

  init() {
    if (!document.querySelector('.topMember')) {
      return false;
    }
    const members = document.querySelectorAll('.p_memberListItem');
    const memberBox = document.querySelector('.p_memberList');
    let moveBase = null;
    let moveMember = null;
    for (let i = 0; i < members.length; i++) {
      members[i].addEventListener('mouseenter', (e) => {
        moveBase = e.currentTarget;
        moveMember = e.currentTarget.querySelector('.c_memberCard');
      });
      members[i].addEventListener('mouseleave', () => {
        moveMember.style.transform = 'translateX(0) translateY(0)';
        moveBase = null;
        moveMember = null;
      });
    }

    memberBox.addEventListener('mousemove', (e) => {
      if (window.matchMedia('(max-width: 768px)').matches) {
        return false;
      }
      const current = moveBase;
      if (!current) {
        return false;
      }
      const target_rect = current.getBoundingClientRect();
      const x = e.clientX - target_rect.left;
      const y = e.clientY - target_rect.top;
      const rx = x / 10;
      const ry = y / 10;
      if (!moveMember) {
        return false;
      }
      moveMember.style.transform = 'translateX(' + rx + 'px)  translateY(' + ry + 'px)';
    });
    memberBox.addEventListener('mouseleave', (e) => {
      if (window.matchMedia('(max-width: 768px)').matches) {
        return false;
      }
    });
  }
}
