import barba from '@barba/core';
import imagesLoaded from 'imagesloaded';

import InViewEvent from './inViewEvent';
import SwiperEvent from './swiperEvent';
import PerspectiveEvent from './perspectiveEvent';
import smoothScroll from 'smooth-scroll';

export default class BarbaEvent {
  constructor() {
    this.init();
  }

  init() {
    if (!document.querySelector('.worksBarba')) {
      return false;
    }
    const that = this;
    this.itemClick();
    console.log('Barbr');
    const scroll = new smoothScroll();
    const shutterA = document.querySelector('.shutter-a');
    const shutterB = document.querySelector('.shutter-b');

    barba.init({
      // ...

      transitions: [
        {
          name: 'default-transition',
          sync: true,
          async beforeEnter() {
            console.log('beforeEnter');
            const worksBarbas = document.querySelectorAll('.worksBarba');
            for (let i = 0; i < worksBarbas.length; i++) {
              worksBarbas[i].classList.add('Out');
            }
            setTimeout(() => {
              shutterB.classList.add('moved');
            }, 50);
            await new Promise((resolve) => {
              return setTimeout(resolve, 1000);
            });
          },

          async leave() {
            // console.log('leave');
          },
          async afterOnce() {
            // console.log('shows up AFTER once transition');
          },

          async afterEnter() {
            // console.log('afterEnter');
            await new Promise((resolve) => {
              imagesLoaded('#barba-wrapper', { background: true }, function () {
                console.log('Load');
                resolve();
              });
            });
            const worksBarbas = document.querySelectorAll('.worksBarba');
            for (let i = 0; i < worksBarbas.length; i++) {
              worksBarbas[i].classList.remove('Out');
            }
            // shutterA.classList.toggle('moved');

            shutterB.classList.add('moveZoom');
            await new Promise((resolve) => {
              return setTimeout(resolve, 600);
            });
            shutterB.classList.add('moveGlitchOut');
            await new Promise((resolve) => {
              return setTimeout(resolve, 300);
            });
            shutterB.classList.add('movedOut');

            const swiperEvent = new SwiperEvent();
            const perspectiveEvent = new PerspectiveEvent();
            setTimeout(() => {
              const Bg = document.querySelector('.worksDetailHeadBg');
              Bg.classList.add('Out');
            }, 0);
            // setTimeout(() => {
            //   shutterA.classList.toggle('movedOut');
            // }, 100);
            setTimeout(() => {
              const Bg = document.querySelector('.worksDetailHeadBg');
              Bg.classList.add('In');
            }, 50);

            setTimeout(() => {
              shutterA.classList.remove('moved');
              shutterA.classList.remove('movedOut');

              shutterB.classList.remove('moved');
              shutterB.classList.remove('movedOut');
              shutterB.classList.remove('moveZoom');
              shutterB.classList.remove('moveGlitchOut');
              that.itemClick();
              const inViewEvent = new InViewEvent();
            }, 1000);
            // shutterB.classList.toggle('moved');
          },
          async enter() {
            console.log('enter');
          },
        },
      ],
    });

    barba.hooks.enter(() => {
      console.log('hooks.enter');
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1000);
    });
  }

  itemClick() {
    const items = document.querySelectorAll('.c_worksThumbList__item');
    for (let i = 0; i < items.length; i++) {
      items[i].addEventListener('click', (e) => {
        const worksThumbImage = e.currentTarget.querySelector('.m_worksThumbImage');
        const worksThumbImagePath = worksThumbImage.querySelector('img').getAttribute('src');
        document.querySelector('.glitch').style.backgroundImage =
          'url(' + worksThumbImagePath + ')';
        document.querySelector('.glitch').style.backgroundRepeat = 'no-repeat';
        document.querySelector('.glitch').style.backgroundSize = '0';
      });
    }
    const figure = document.querySelector('.worksThumbFigure');
    if (!figure) {
      return false;
    }
    figure.addEventListener('click', (e) => {
      const worksThumbImage = e.currentTarget.querySelector('.worksThumbImageInner');
      const worksThumbImagePath = worksThumbImage.querySelector('img').getAttribute('src');
      document.querySelector('.glitch').style.backgroundImage = 'url(' + worksThumbImagePath + ')';
      document.querySelector('.glitch').style.backgroundRepeat = 'no-repeat';
      document.querySelector('.glitch').style.backgroundSize = '0';
    });
  }
}
