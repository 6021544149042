const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
import smoothScroll from 'smooth-scroll';

export default class NavEvent {
  constructor() {
    this.init();
  }

  init() {
    const toggle = document.querySelector('.p_headerNavToggle');
    const menu = document.querySelector('.p_headerMenu');
    const menuLogo = document.querySelector('.p_headerMenuLogo');
    const menuNav = document.querySelector('.p_headerMenuMain');
    const navItems = document.querySelectorAll('.p_headerMenuMainList__item');

    toggle.addEventListener('click', () => {
      toggle.classList.toggle('InMenu');
      menu.classList.toggle('Act');
      if (menu.classList.contains('Act')) {
        // disableBodyScroll(menuNav);
        menu.classList.remove('Hide');
      } else {
        // enableBodyScroll(menuNav);
        menu.classList.add('Out');
        setTimeout(() => {
          menu.classList.add('Hide');
        }, 400);
        setTimeout(() => {
          menu.classList.remove('Out');
        }, 700);
      }
    });

    let smoothScrollObj = document.getElementsByClassName('js-smoothScroll');
    let option = {
      speed: 10,
      durationMax: 1000,
      durationMin: 800,
      clip: true,
      offset: 0,
      easing: 'easeInOutQuint',
      updateURL: false,
      popstate: false,
    };
    for (let i = 0; i < smoothScrollObj.length; i++) {
      smoothScrollObj[i].addEventListener('click', function (e) {
        let scroll = new smoothScroll('a[href*="#"]', option);
        menuWrap.classList.remove('Act');
        menuToggle.classList.remove('Act');
        enableBodyScroll(menuWrapInner);
      });
    }

    for (let i = 0; i < navItems.length; i++) {
      navItems[i].addEventListener('click', function (e) {
        toggle.classList.toggle('InMenu');
        menu.classList.toggle('Hide');
        menu.classList.toggle('Act');
        if (menu.classList.contains('Act')) {
          disableBodyScroll(menuNav);
        } else {
          enableBodyScroll(menuNav);
        }
      });
    }
  }
}
