export default class PerspectiveEvent {
  constructor() {
    this.init();
  }

  init() {
    if (!document.querySelector('.js-perspectiveEvent')) {
      return false;
    }

    const cardBox = document.querySelector('.worksThumbFigure');
    const cardImag = document.querySelector('.worksThumbImageInner');

    cardBox.addEventListener('mousemove', (e) => {
      if (window.matchMedia('(max-width: 768px)').matches) {
        return false;
      }
      const current = e.currentTarget;
      const target_rect = current.getBoundingClientRect();
      const x = e.clientX - target_rect.left;
      const y = e.clientY - target_rect.top;
      const xx = current.clientWidth - x * 2;
      const yy = current.clientHeight - y * 2;
      const rx = -xx / 35 + 10;
      const ry = yy / 75 + 10;
      cardImag.style.transform =
        'scaleX(1) scaleY(1) scaleZ(1) translateX(0px) translateY(0px) translateZ(0px) rotateY(' +
        rx +
        'deg) rotateX(' +
        ry +
        'deg) rotateZ(0deg)';
    });
    cardBox.addEventListener('mouseleave', (e) => {
      if (window.matchMedia('(max-width: 768px)').matches) {
        return false;
      }
      cardImag.style.transform = 'rotateX(30deg) rotateY(10deg) rotateZ(0deg)';
    });
  }
}
