// core version + navigation, pagination modules:
import Swiper from 'swiper/bundle';
// const bodyScrollLock = require('body-scroll-lock');
// const disableBodyScroll = bodyScrollLock.disableBodyScroll;
// const enableBodyScroll = bodyScrollLock.enableBodyScroll;
export default class SwiperEvent {
  constructor() {
    this.init();
  }

  init() {
    if (document.querySelector('.worksSwiper')) {
      const worksBar = document.querySelector('.p_worksNavBarInner');
      const worksSwiper = new Swiper('.worksSwiper', {
        loop: true,
        slidesPerView: 1.3,
        spaceBetween: 48,
        speed: 1000,
        breakpoints: {
          786: {
            loop: true,
            slidesPerView: 2.5,
            spaceBetween: 48,
          },
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          stopOnLastSlide: true,
        },
        on: {
          init: function (e) {
            worksBar.style.width = '0%';
          },
        },
      });

      worksSwiper.on('slideChange', (e) => {
        // console.log(e);
        worksBar.style.width = (Number(e.realIndex + 1) / e.slides.length) * 100 + '%'; //e.progress * 100 + '%';
      });
    }

    if (document.querySelector('.memberSwiper')) {
      // if (window.matchMedia('(max-width:767px)').matches) {
      const memberNum = document.querySelector('.topMemberModalNum');
      const closeButton = document.querySelector('.topMemberModalClose');
      const nextArrow = document.querySelector('.topMemberModalNext');
      const prevArrow = document.querySelector('.topMemberModalPrev');
      const modalBG = document.querySelector('.topMemberModalBg');
      const modal = document.querySelector('.topMemberModal');

      const membersList = document.querySelectorAll('.p_memberListItem');

      const memberSwiper = new Swiper('.memberSwiper', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 48,
        speed: 500,
        on: {
          init: function (e) {
            memberNum.innerHTML = '<span>' + 1 + '</span> / ' + e.slides.length;
          },
        },
      });
      memberSwiper.on('slideChange', (e) => {
        memberNum.innerHTML =
          '<span>' + Number(memberSwiper.realIndex + 1) + '</span> / ' + e.slides.length;
      });
      nextArrow.addEventListener('click', (e) => {
        memberSwiper.slideNext();
      });
      prevArrow.addEventListener('click', (e) => {
        memberSwiper.slidePrev();
      });

      //
      closeButton.addEventListener('click', () => {
        modal.classList.toggle('Hide');
        modal.classList.toggle('Act');
      });
      modalBG.addEventListener('click', () => {
        modal.classList.toggle('Hide');
        modal.classList.toggle('Act');
      });
      for (let i = 0; i < membersList.length; i++) {
        membersList[i].addEventListener('click', (e) => {
          if (!window.matchMedia('(max-width:767px)').matches) {
            return false;
          }
          memberSwiper.slideTo(e.currentTarget.dataset.index, 0);
          modal.classList.toggle('Hide');
          modal.classList.toggle('Act');
        });
      }
      // }
    }

    if (document.querySelector('.worksDetailSwiper')) {
      const mqlSP = window.matchMedia('(max-width:767px)');
      const mqlPC = window.matchMedia('(min-width:768px)');
      let worksDetailSwiper;

      handle(mqlSP);
      function handle(mm) {
        if (mm.matches) {
          worksDetailSwiper = new Swiper('.worksDetailSwiper', {
            loop: true,
            slidesPerView: 1.3,
            spaceBetween: 48,
            speed: 500,
            // breakpoints: {
            //   786: {
            //     loop: true,
            //     slidesPerView: 2.5,
            //     spaceBetween: 48,
            //   },
            // },
          });
        }
      }

      mqlSP.addEventListener('change', (ev) => {
        if (ev.matches) {
          worksDetailSwiper = new Swiper('.worksDetailSwiper', {
            loop: true,
            slidesPerView: 1.5,
            spaceBetween: 48,
            speed: 500,
          });
        }
      });
      mqlPC.addEventListener('change', (ev) => {
        if (ev.matches) {
          worksDetailSwiper.destroy();
        }
      });
    }
  }
}
